import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import Modal from 'react-modal'
import { PageRenderer } from 'gatsby'

import { color, spacing } from '@papertrailio/react-web-core/style/theme'
import HorizontalRule from '@papertrailio/react-web-core/components/text/HorizontalRule'
import SitePreFooter from '@papertrailio/react-web-core/components/footer/SitePreFooter'
import SiteFooter from '@papertrailio/react-web-core/components/footer/SiteFooter'
import ContentContainer from '@papertrailio/react-web-core/components/ContentContainer'
import SiteLinksSection from '@papertrailio/react-web-core/components/footer/SiteLinksSection'
import PapertrailLogo from '@papertrailio/react-web-core/components/PapertrailLogo'
import ModalHeader from '@papertrailio/react-web-core/components/modal/ModalHeader'

import SiteHeader from '../components/SiteHeader'
import Link from '../components/Link'
import { HelmetSetup } from '../components/Helmet'

import isModalCheck from '../utils/isModalCheck'

const GlobalStyle = createGlobalStyle`
    html {
    -webkit-font-smoothing: antialiased;
  }

  body {
    overflow-x: hidden;
    font-size: 1.6rem;
  }

  img,
  svg {
    margin-bottom: 0;
    vertical-align: bottom;
  }

  a {
    color: inherit;
  }

  .gatsby-image-outer-wrapper {
    display: contents;
  }

  [hidden] {
    display: block;
  }
`;

const HeaderOuterContainer = styled.div`
  position: relative;
  z-index: 1;
`

const HeaderBackground = styled.div`
  position: absolute;
  background: ${props => (props.hasHeaderContent ? '#f9f9fb' : 'none')};
  height: 200%;
  width: 200%;
  bottom: 0px;
  left: -50%;
  transform: rotate(355deg);
`

const HeaderContent = styled(ContentContainer)``

const ContentContainerStyled = styled(ContentContainer)`
  padding-top: ${spacing.base * 2}px;
  padding-bottom: ${spacing.base * 2}px;

  @media only screen and (max-width: ${spacing.collapseMenuWidth}px) {
    padding-top: ${spacing.base}px;
    padding-bottom: ${spacing.base}px;
  }
`

const ContentMain = styled.div`
  padding-top: ${props =>
    props.hasHeaderContent ? spacing.base * 4 : spacing.base * 2}px;

  @media only screen and (max-width: ${spacing.collapseMenuWidth}px) {
    padding-top: ${spacing.base * 2}px;
  }
`

const PapertrailLogoHeading = styled.div`
  margin-top: ${spacing.base}px;
  margin-bottom: ${spacing.base * 2}px;

  @media only screen and (max-width: ${spacing.collapseMenuWidth}px) {
    margin-bottom: ${spacing.base}px;
  }
`

const ContentContainerFooterStyled = styled(ContentContainerStyled)`
  margin-bottom: ${spacing.base * 2}px;
`

class Layout extends Component {
  handleCloseModal = () => {
    window.history.go(-1)
  }

  componentDidMount = () => {
    Modal.setAppElement(`#___gatsby`)

    if (isModalCheck(this.props.isModal)) {
      document.querySelector(`html`).style.overflowY = `hidden`
    }
  }

  componentWillUnmount() {
    document.querySelector(`html`).style.overflowY = `auto`
  }

  render = () => {
    const { children, modalBackgroundPath, headerContent } = this.props

    if (isModalCheck(this.props.isModal)) {
      return (
        <Fragment>
          <GlobalStyle />

          <PageRenderer location={{ pathname: modalBackgroundPath }} />

          <Modal
            isOpen={true}
            style={{
              content: {
                top: `inherit`,
                left: `inherit`,
                right: `inherit`,
                bottom: `inherit`,
                margin: `0 auto`,
                width: `750px`,
                background: `none`,
                border: `none`,
                padding: `40px 0 84px 0`,
                overflow: `visible`,
              },
              overlay: {
                position: `absolute`,
                top: 0,
                left: 0,
                right: 0,
                bottom: `unset`,
                minHeight: `100%`,
                minWidth: `100%`,
                zIndex: 10,
                overflowY: `auto`,
                backgroundColor: `rgba(33, 56, 84, 0.8)`,
              },
            }}
            onRequestClose={this.handleCloseModal}
            contentLabel="Product Information Modal"
          >
            <div
              style={{
                backgroundColor: `#ffffff`,
                boxShadow: `0 0 90px -24px #213854`,
                position: `relative`,
              }}
            >
              <ModalHeader
                title="Product Information"
                closeModal={this.handleCloseModal}
              />
              <div
                style={{
                  padding: `${spacing.base}px`,
                  paddingTop: `${spacing.halve}px`,
                }}
              >
                {children}
              </div>
            </div>
          </Modal>
        </Fragment>
      )
    }

    return (
      <div id="root">
        <HelmetSetup />

        <GlobalStyle />

        <HeaderOuterContainer>
          <HeaderBackground hasHeaderContent={!!headerContent} />
          <HeaderContent>
            <SiteHeader />
            {headerContent && headerContent()}
          </HeaderContent>
        </HeaderOuterContainer>

        <ContentMain hasHeaderContent={!!headerContent}>{children}</ContentMain>

        <HorizontalRule />

        <ContentContainerStyled>
          <PapertrailLogoHeading>
            <PapertrailLogo className="blue" />
          </PapertrailLogoHeading>
          <SiteLinksSection
            Link={Link}
            links={[
              {
                name: 'Solutions',
                links: [
                  {
                    name: 'PPE Inspection Management',
                    href:
                      'https://www.papertrail.io/ppe-inspection-management',
                    props: { target: '_blank' },
                  },
                  {
                    name: 'Product Directory',
                    href: '/',
                  },
                ],
              },
              {
                name: 'Business',
                links: [
                  {
                    name: 'Features',
                    href: 'https://www.papertrail.io/features',
                    props: { target: '_blank' },
                  },
                ],
              },
              {
                name: 'Support',
                links: [
                  {
                    name: 'Events',
                    href: 'https://www.papertrail.io/events',
                    props: { target: '_blank' },
                  },
                  {
                    name: 'Help Centre',
                    href: 'http://help.papertrail.io',
                    props: { target: '_blank' },
                  },
                  {
                    name: 'Resources',
                    href: 'https://www.papertrail.io/resources',
                    props: { target: '_blank' },
                  },
                  {
                    name: 'Updates',
                    href: 'https://updates.papertrail.io',
                    props: { target: '_blank' },
                  },
                ],
              },
              {
                name: 'Company',
                links: [
                  {
                    name: 'Contact Us',
                    href: 'https://www.papertrail.io/about#Contact',
                    props: { target: '_blank' },
                  },
                  {
                    name: 'Manufacturing Partners',
                    href: 'https://www.papertrail.io/manufacturer-partners',
                    props: { target: '_blank' },
                  },
                  {
                    name: 'Become a Partner',
                    href: 'https://www.papertrail.io/become-a-partner',
                    props: { target: '_blank' },
                  },
                  {
                    name: 'Team',
                    href: 'https://www.papertrail.io/about',
                    props: { target: '_blank' },
                  },
                  {
                    name: 'Careers',
                    href: 'https://www.papertrail.io/career',
                    props: { target: '_blank' },
                  },
                ],
              },
            ]}
          />
        </ContentContainerStyled>
        <HorizontalRule />
        <ContentContainerStyled>
          <SitePreFooter
            Link={Link}
            links={[
              {
                name: 'Legal',
                href: 'https://www.papertrail.io/legal',
                props: { target: '_blank' },
              },
              {
                name: 'Privacy',
                href: 'https://www.papertrail.io/privacy',
                props: { target: '_blank' },
              },
            ]}
          />
        </ContentContainerStyled>
        <HorizontalRule />
        <ContentContainerFooterStyled>
          <ThemeProvider theme={{ color: color.secondary1 }}>
            <SiteFooter />
          </ThemeProvider>
        </ContentContainerFooterStyled>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object,
  isModal: PropTypes.bool,
  modalBackgroundPath: PropTypes.string,
  headerContent: PropTypes.func,
}

export default Layout
