import React, { Fragment } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { MdLaunch } from 'react-icons/md'
import PubSub from 'pubsub-js'

import Button from '@papertrailio/react-web-core/components/Button'
import { spacing } from '@papertrailio/react-web-core/style/theme'

import * as productsList from '@papertrailio/react-web-core/utils/productsList'
import Link from './Link'
import { ReactComponent as WhiteIcon } from '@papertrailio/react-web-core/img/pt-white-icon.svg'

const PTLogo = styled(WhiteIcon).attrs({ fill: '#fff' })`
  height: 15px;
  width: 20px;
  margin-bottom: 6px;
  margin-right: 6px;
`

class ListButton extends React.Component {
  pubSubToken

  constructor(props) {
    super(props)

    this.state = {
      quantity: 0,
      quantityFetched: false,
    }
  }

  componentDidMount = () => {
    if (window) {
      this.pubSubToken = PubSub.subscribe(
        productsList.NOTIFY_CHANGES,
        this._fetchQuantity
      )

      this._fetchQuantity()
    }
  }

  componentWillUnmount = () => {
    PubSub.unsubscribe(this.pubSubToken)
  }

  _fetchQuantity = () =>
    productsList.getQuantity().then(quantity => {
      this.setState({
        quantityFetched: true,
        quantity,
      })
    })

  render = () => (
    <Button theme="primaryRed" to="/import-list" Link={Link} {...this.props}>
      <PTLogo />
      View import list (
      {this.state.quantityFetched ? this.state.quantity : '--'})
    </Button>
  )
}

export default ListButton
