import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'
import { rgba } from 'polished'

import { color, spacing } from '@papertrailio/react-web-core/style/theme'
import manufacturerLogoUrl from '@papertrailio/react-web-core/utils/manufacturerLogoUrl'

import slug from '../utils/slug'

const SelectContainer = styled.div`
  width: 100%;
  position: relative;
`

const AlternativeMessage = styled.p`
  margin-bottom: ${spacing.halve}px;
`

const Select = styled.div`
  display: flex;
  box-shadow: 0px 0px 20px 0px ${rgba(color.secondary1, 0.15)};
  height: 60px;
  cursor: pointer;
`
const SelectValue = styled.div`
  background: ${color.white};
  width: 100%;
  padding-left: 12px;
  display: flex;
  align-items: center;
  display: flex;
`

const SelectButton = styled.button`
  cursor: pointer;
  background: ${color.white};
  border: 0;
  font-size: ${spacing.base}px;
  line-height: ${spacing.base}px;
  cursor: pointer;
`

const UpArrow = styled(MdKeyboardArrowUp)`
  color: ${color.secondary1};
  opacity: 0.8;
`

const DownArrow = styled(MdKeyboardArrowDown)`
  color: ${color.secondary1};
  opacity: 0.8;
`

const OptionLabelContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 12px;
  background-color: ${props =>
    props.isSelected
      ? color.primaryBlue
      : props.isFocused
        ? rgba(color.primaryBlue, 0.15)
        : color.white};
  color: ${props => (props.isSelected ? color.white : color.secondary1)};
`

const OptionLabelLogo = styled.div`
  width: 60px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: center;
  background-image: url(${props => props.url});
`

const OptionLabelName = styled.span`
  margin-left: ${spacing.halve}px;
`

const OptionLabelPlaceholder = styled.span`
  margin-left: ${spacing.base}px;
  color: ${color.secondary1};
  opacity: 0.65;
`

const OptionsList = styled.ul`
  position: absolute;
  width: 100%;
  margin: 0;
  margin-top: ${spacing.halve}px;
  list-style: none;
  box-shadow: 0px 0px 20px 0px ${rgba(color.secondary1, 0.15)};
  background-color: ${color.white};
  overflow-x: auto;
  max-height: 270px;
`

const OptionsListItem = styled.li`
  margin: 0;
`

const HiddenInput = styled.input`
  transform: scale(0);
  position: absolute;
`

const ManufacturerSelect = ({ manufacturers, manufacturer }) => {
  const selectOption = {
    label: manufacturer || '',
    id: (manufacturer && slug(manufacturer)) || '',
  }

  const selectOptions = manufacturers.map(manufacturer => ({
    label: manufacturer,
    id: slug(manufacturer),
  }))

  return (
    <Downshift
      onChange={selection =>
        selection && selection.id ? navigate(`/${selection.id}/#content`) : null
      }
      itemToString={i => (i ? i.name : '')}
      defaultSelectedItem={selectOption || { id: '' }}
      defaultInputValue={selectOption ? selectOption.label : ''}
    >
      {({
        getRootProps,
        getLabelProps,
        getInputProps,
        getToggleButtonProps,
        getMenuProps,
        getItemProps,
        isOpen,
        toggleMenu,
        clearSelection,
        selectedItem,
        inputValue,
        highlightedIndex,
        openMenu,
      }) => (
        <SelectContainer {...getRootProps({ refKey: 'refKey' })}>
          <label {...getLabelProps()}>
            <AlternativeMessage>
              Alternatively, select a manufacturer to view all available
              products
            </AlternativeMessage>
          </label>

          <Select>
            <HiddenInput
              {...getInputProps({
                placeholder: 'Select a manufacturer...',
                readOnly: 'readOnly',
                tabIndex: 0,
              })}
            />

            <SelectValue onClick={toggleMenu}>
              {!selectedItem.id && (
                <OptionLabelPlaceholder>
                  Select a manufacturer...
                </OptionLabelPlaceholder>
              )}

              {selectedItem.id && (
                <Fragment>
                  <OptionLabelLogo
                    url={manufacturerLogoUrl(selectedItem.id, 150)}
                  />

                  <OptionLabelName>{selectedItem.label}</OptionLabelName>
                </Fragment>
              )}
            </SelectValue>

            <SelectButton {...getToggleButtonProps()}>
              {isOpen ? <UpArrow /> : <DownArrow />}
            </SelectButton>
          </Select>
          <OptionsList {...getMenuProps()}>
            {isOpen &&
              selectOptions.map((item, index) => (
                <OptionsListItem
                  key={item.id}
                  {...getItemProps({
                    item,
                  })}
                >
                  <OptionLabelContainer
                    isSelected={selectedItem && selectedItem.id === item.id}
                    isFocused={highlightedIndex === index}
                  >
                    <OptionLabelLogo url={manufacturerLogoUrl(item.id, 150)} />

                    <OptionLabelName>{item.label}</OptionLabelName>
                  </OptionLabelContainer>
                </OptionsListItem>
              ))}
          </OptionsList>
        </SelectContainer>
      )}
    </Downshift>
  )
}

ManufacturerSelect.propTypes = {
  manufacturers: PropTypes.array.isRequired,
  manufacturer: PropTypes.string,
}

export default ManufacturerSelect
