const isModalCheck = isModalProp => {
  let windowWidth

  if (!windowWidth && typeof window !== `undefined`) {
    windowWidth = window.innerWidth
  }

  return isModalProp && windowWidth > 750
}

export default isModalCheck
