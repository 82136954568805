import React from 'react'
import PropTypes from 'prop-types'
import ReactHelmet from 'react-helmet'

export const HelmetSetup = () => (
  <ReactHelmet titleTemplate="%s | Papertrail.io Product Directory">
    <link
      rel="shortcut icon"
      type="image/png"
      href="https://uploads-ssl.webflow.com/5fd110263ed35ff3e1e1b669/5fd110263ed35f6cbbe1b706_Mask%20Group.png"
    />
    <meta property="fb:app_id" content="750189481699600" />
    <meta property="og:site_name" content="Papertrail.io Product Directory" />
    <meta property="og:type" content="website" />
    <meta name="twitter:site" content="@papertrail_io" />
    <meta name="twitter:creator" content="@papertrail_io" />
    <meta name="twitter:card" content="summary_large_image" />
    <html lang="en" />
  </ReactHelmet>
)

const Helmet = ({ title, description, image }) => (
  <ReactHelmet>
    <title>{title}</title>
    <meta name="twitter:description" content={description} />
    <meta
      name="image"
      content={image || 'https://uploads-ssl.webflow.com/5fd110263ed35ff3e1e1b669/6047cdf316a1ec47994dfebf_Papertrail%20og-min.png'}
    />
    <meta name="description" content={description || title} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta
      property="og:image"
      content={image || 'https://uploads-ssl.webflow.com/5fd110263ed35ff3e1e1b669/6047cdf316a1ec47994dfebf_Papertrail%20og-min.png'}
    />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta
      name="twitter:image:src"
      content={image || 'https://uploads-ssl.webflow.com/5fd110263ed35ff3e1e1b669/6047cdf316a1ec47994dfebf_Papertrail%20og-min.png'}
    />
  </ReactHelmet>
)

Helmet.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default Helmet
