import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { color, spacing } from '@papertrailio/react-web-core/style/theme'

import ManufacturerSelect from './ManufacturerSelect'
import ProductSearch from './ProductSearch'

import headerImage from '../img/header-image.png'
import headerImage2x from '../img/header-image@2x.png'

const Content = styled.div`
  display: flex;
`

const ContentInner = styled.div`
  margin-top: ${spacing.base * 2}px;
  padding-bottom: ${spacing.base}px;
  flex: 2;

  @media only screen and (max-width: 750px) {
    margin-top: ${spacing.base}px;
    padding-bottom: ${spacing.base * 2}px;
  }
`

const ImageContainer = styled.div`
  text-align: center;
  flex: 1.5;
  margin-top: -${spacing.base * 1.5}px;

  @media only screen and (max-width: 750px) {
    display: none;
  }
`

const Title = styled.h1`
  @media only screen and (max-width: 750px) {
    font-size: 28px;
  }
`

const Dot = styled.span`
  color: ${color.primaryRed};
`

const SubTitle = styled.p`
  font-size: ${spacing.baseFontSize + 2}px;
`

const ProductSearchStyled = styled(ProductSearch)`
  display: flex;
  align-items: center;
  z-index: 10;
  margin-bottom: ${spacing.base}px;
`

const Break = styled.br`
  @media only screen and (max-width: 750px) {
    display: none;
  }
`

const HeaderContent = ({ listPagePath, ...props }) => (
  <Content>
    <ContentInner>
      <Title>
        Search and explore <Break />
        the Product Directory
        <Dot>.</Dot>
      </Title>

      <SubTitle>
        Products can be directly imported into your Papertrail account
      </SubTitle>

      <ProductSearchStyled listPagePath={listPagePath} />

      <ManufacturerSelect {...props} />
    </ContentInner>

    <ImageContainer>
      <img
        src={headerImage}
        srcSet={`${headerImage} 1x, ${headerImage2x} 2x`}
        alt=""
      />
    </ImageContainer>
  </Content>
)

HeaderContent.propTypes = {
  listPagePath: PropTypes.string.isRequired,
}

export default HeaderContent
