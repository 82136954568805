import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import PropTypes from 'prop-types'

import ContentContainer from '@papertrailio/react-web-core/components/ContentContainer'
import ImageContentContainer from '@papertrailio/react-web-core/components/ImageContentContainer'

import * as UniqueSellingPoint from '@papertrailio/react-web-core/components/UniqueSellingPoint'

import { spacing } from '@papertrailio/react-web-core/style/theme'

import { ReactComponent as DMMLogo } from '../img/dmm-logo.svg'

import dmmProductScreenshot from '../img/dmm-product-screenshot.png'
import dmmProductScreenshot2x from '../img/dmm-product-screenshot@2x.png'
import dmmQuoteBackground from '../img/partner-quote-background.png'
import richardRustHeadshot from '../img/richard-rust-headshot.png'

const DarkBackgroundContainer = styled.div`
  background-image: url(${dmmQuoteBackground});
  background-size: cover;
  margin-bottom: ${spacing.base * 6}px;

  @media only screen and (max-width: ${spacing.collapseMenuWidth}px) {
    padding-top: ${spacing.base}px;
    padding-bottom: ${spacing.base}px;
    margin-bottom: ${spacing.base * 2}px;
  }
`

const Testimonial = () => (
  <DarkBackgroundContainer>
    <ImageContentContainer
      rightToLeft
      imageOverhang={true}
      image={dmmProductScreenshot}
      image2x={dmmProductScreenshot2x}
      alt="Importing products into Papertrail mobile app"
    >
      <ThemeProvider theme={{ color: '#fff' }}>
        <div>
          <UniqueSellingPoint.Logo
            svg={{ svg: DMMLogo }}
            width="100px"
            fill={'#fff'}
          />

          <UniqueSellingPoint.Title>
            Simple, quick and accurate record creation with Papertrail
          </UniqueSellingPoint.Title>

          <UniqueSellingPoint.Quote
            text="When used in conjunction with the Papertrail equipment management system, record creation is simple, quick and accurate for your customers."
            image={{
              src: richardRustHeadshot,
              alt: 'Headshot of Richard Rust from DMM International',
            }}
            person={{
              name: 'Richard Rust - Group Financial Director',
              company: 'DMM International',
            }}
          />
        </div>
      </ThemeProvider>
    </ImageContentContainer>
  </DarkBackgroundContainer>
)

export default Testimonial
