import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import { StaticQuery, graphql, navigate } from 'gatsby'
import { MdSearch } from 'react-icons/md'

import { color, spacing } from '@papertrailio/react-web-core/style/theme'
import ProductSearchWidget from '@papertrailio/react-web-core/components/product-search/ProductSearch'

import slug from '../utils/slug'

const TextInputStyled = styled.input`
  padding-left: ${spacing.base * 1.5}px;
  border: 1px solid ${rgba(color.secondary1, 0.1)};
  box-shadow: 0px 0px 20px 0px ${rgba(color.secondary1, 0.15)};
  width: 100%;
  height: 60px;

  & ::placeholder {
    color: ${color.secondary1};
    opacity: 0.65;
  }
`

const SearchIcon = styled(MdSearch)`
  position: absolute;
  font-size: 20px;
  margin-left: 10px;
  color: ${color.secondary1};
  opacity: 0.8;
`

const searchResultClick = (product, listPagePath) => {
  navigate(`/${slug(product.manufacturer)}/product-details/`, {
    state: {
      isModal: true,
      listPagePath,
      product,
    },
  })
}

const ProductSearchQuery = props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            appUrl {
              production
              develop
            }
          }
        }
      }
    `}
    render={data => (
      <ProductSearch appUrl={data.site.siteMetadata.appUrl} {...props} />
    )}
  />
)

const ProductSearch = ({ appUrl, listPagePath, ...rest }) => (
  <ProductSearchWidget
    products={[]}
    searchEndpoint="/.netlify/functions/product-search"
    searchResultClick={product => searchResultClick(product, listPagePath)}
    textInput={(handleChange, value) => (
      <TextInputStyled
        placeholder="Search - Name, Serial, Barcode, RFID..."
        name="product-search"
        onChange={handleChange}
        value={value}
      />
    )}
    searchIcon={() => <SearchIcon />}
    {...rest}
  />
)

ProductSearch.propTypes = {
  appUrl: PropTypes.shape({
    production: PropTypes.string.isRequired,
    develop: PropTypes.string.isRequired,
  }).isRequired,
  listPagePath: PropTypes.string.isRequired,
}

export default ProductSearchQuery
