import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'

import trackEvent from '@papertrailio/react-web-core/utils/track'

const Link = ({ to, href, track, onClick, ...props }) => {
  const destination = href || to

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(destination)

  // Use gatsby-link for internal links, and <a> for others
  if (internal && !destination.endsWith('.pdf')) {
    return (
      <GatsbyLink
        to={destination}
        {...props}
        onClick={event => {
          if (onClick) {
            onClick(event)
          }

          if (track) {
            trackEvent(track)
          }
        }}
      />
    )
  }
  return (
    <a
      href={destination}
      {...props}
      onClick={event => {
        if (onClick) {
          onClick(event)
        }

        if (track) {
          trackEvent(track)
        }
      }}
    />
  )
}

Link.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  track: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
  }),
}

export default Link
