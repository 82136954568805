import React, { Fragment } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { MdLaunch } from 'react-icons/md'

import { spacing, color } from '@papertrailio/react-web-core/style/theme'
import PapertrailLogo from '@papertrailio/react-web-core/components/PapertrailLogo'
import Button from '@papertrailio/react-web-core/components/Button'
import ListButton from '../components/ListButton'

import Link from './Link'

const SkipToContent = styled.a`
  position: absolute;
  left: -999px;

  &:focus {
    left: ${spacing.base}px;
  }
`

const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: ${spacing.base}px;
  padding-bottom: ${spacing.base}px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1;

  @media only screen and (max-width: 880px) {
    flex-direction: column;
    align-items: start;
  }
`

const TitleLink = styled(Link)`
  display: flex;
  text-decoration: none;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const SiteTitle = styled.h3`
  display: inline;
  margin: 0;
  padding: 0;
  line-height: 28px;
  font-size: 28px;
  border-left: 1px solid #8f9ba9;
  margin-left: ${spacing.base * 0.75}px;
  padding-left: ${spacing.base * 0.75}px;

  @media only screen and (max-width: 500px) {
    margin: 12px 0 0 -2px;
    border: none;
    padding: 0;
  }
`

const ButtonStyled = styled(Button)`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 880px) {
    margin-top: ${spacing.halve}px;
    padding: ${spacing.base / 4}px ${spacing.base / 2}px;
  }

  @media only screen and (max-width: 420px) {
    display: none;
  }
`

const MdLaunchContainer = styled.div`
  font-size: 18px;
  line-height: 18px;
  margin-right: ${spacing.quarter}px;
`

const ListButtonStyled = styled(ListButton)`
  margin-right: ${spacing.base}px;

  @media only screen and (max-width: 880px) {
    margin-top: ${spacing.halve}px;
    padding: ${spacing.base / 4}px ${spacing.base / 2}px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-end;
`

const ButtonContainer = styled.div`
  display: flex;
`

const SiteHeader = () => (
  <Fragment>
    <SkipToContent href="#content">Skip to content</SkipToContent>

    <StyledHeader>
      <TitleLink href="/">
        <PapertrailLogo className={'blue-red'} />
        <SiteTitle>Product Directory</SiteTitle>
      </TitleLink>

      <ButtonsContainer>
        <ButtonContainer>
          <ListButtonStyled />
        </ButtonContainer>

        <ButtonContainer>
          <ButtonStyled
            theme="primaryRed"
            to="https://www.papertrail.io/?utm_source=product-directory&utm_campaign=menu-button"
            Link={Link}
            target="_blank"
          >
            <MdLaunchContainer>
              <MdLaunch />
            </MdLaunchContainer>
            Go to Papertrail
          </ButtonStyled>
        </ButtonContainer>
      </ButtonsContainer>
    </StyledHeader>
  </Fragment>
)

export default SiteHeader
